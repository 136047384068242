.tap-button {
    width: 120px;
}

.center-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h1 {
    font-family: "Chalkboard SE";
}

h5 {
    font-family: "Chalkboard SE";
}

.diamond {
    -webkit-transform: rotate(45deg);
}
